'use strict';

module.exports = string => {
    ;(function() {
        if (!Element.prototype.matches) {

            Element.prototype.matches = Element.prototype.matchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector;

        }
    })();

    var activateResizeHandler = function() {
        var win = jQuery(window),
            doc = jQuery('html'),
            resizeClass = 'resize-active',
            burger = jQuery('.burger-menu'),
            flag, timer;

        var removeClassHandler = function() {
            flag = false;
            doc.removeClass(resizeClass);
        };

        var windowWidth = jQuery(window).width();

        var resizeHandler = function() {
            if (win.innerWidth() >= 1024) {
                doc.children('body').removeClass('nav-active');
                burger.removeClass('active')
            }

            if (!flag) {
                flag = true;
                doc.addClass(resizeClass);
            }

            clearTimeout(timer);
            timer = setTimeout(removeClassHandler, 500);
        };

        win.on('resize orientationchange', resizeHandler);
    };

    function initBurgerMenu() {
        var burger = jQuery('.burger-menu'),
            body = jQuery('body');

        burger.on('click', function(e) {
            e.preventDefault();
            jQuery(this).toggleClass('active');
            body.toggleClass('nav-active');
        });
    }

    function getAjax() {
        var btn = '.add-product';
        var basket = jQuery('.basket');
        var dur = 5000;

        jQuery('.recommendation, .events-wrap').on('click', '.add-product', function (e) {
            e.preventDefault();

            let caption,
                captionH2;

            // if($(this).closest('.inner-content').find('h2').length) {
            //     captionH2 = $(this).closest('.inner-content').find('h2');
            //     caption = captionH2.text();
            // }
            // else if ($(this).closest('.inner-content').find('h1').length) {
            //     captionH2 = $(this).closest('.inner-content').find('h1');
            //     caption = captionH2.text();
            // }
            // else if ($(this).closest('.item.first').find('h1')) {
            //     captionH2 = $(this).closest('.item.first').find('h1');
            //     caption = captionH2.text();
            // }
            // else {
            //     caption;
            // }

            if ($(this).parents('.col, .item').find('.inner-content h1, .inner-content h2')) {
                caption = $(this).parents('.col, .item').find('.inner-content h1, .inner-content h2, > h1, > h2').text().trim()
            } else {
                caption;
            }

            var id = jQuery(e.target).attr('data-id');
            var count = e.target.parentNode.querySelector('.counter-wrap input').value;
            var p = `<div class="thx-msg"><span>Das Produkt "${caption}" wurde zu Ihrem <a class="trigger_click" href="/warenkorb">Warenkorb</a> hinzugefügt </span><button id="msg_close"><img src="../../wp-content/themes/strada/assets/dist/img/close_icon.png" alt="close"></button></div>`;
            var self = this;

            jQuery.ajax({
                type: 'post',
                url: ajax_url.ajaxurl,
                data: {
                    action: "bg_add_to_cart",
                    id: id,
                    count: count
                },

                success: function (data) {
                    //console.log(data);
                    if (data.cart_quantity <= 0) {
                        data.cart_quantity = 1;
                    }

                    if(jQuery('.thx-msg').length) {
                        jQuery('.thx-msg').remove();
                    }

                    basket.attr('data-count', data.cart_quantity);
                    /*jQuery(self).after(p);*/
                     //content
                    $('ul.list').html(data.content);
                    //total
                    $('.price-cart').text(data.total);

                    jQuery('body').append(p);

                    if ($('.thx-msg span').height() > 90) {
                        $('.thx-msg span').text(function(i, el) {
                            var len = el.length / 1.7;

                            return el.slice(0, -len) + '...';
                        });
                    }

                    setTimeout(function() {
                        jQuery('.thx-msg').remove();
                    }, dur);
                }
            });

        });
    }

    $(document).on("click", ".thx-msg .trigger_click", function(e) {
        e.preventDefault();
        jQuery('.basket_wrap').addClass('open');
        jQuery('body').addClass('openBasket');
        jQuery('.thx-msg').remove();
    });

    $(document).on("click", "#msg_close", function(e) {
        e.preventDefault();
        jQuery('.thx-msg').remove();
    });

    function initCounter() {
        var btn = document.querySelectorAll('.recommendation .row [class*="btn_"], .events-wrap .row [class*="btn_"], .recommendation .col-wrap [class*="btn_"]'),
            i = btn.length;
        var input = document.querySelectorAll('.events-wrap .row, .recommendation .row, .basket_wrap'),
            j = input.length;
        var delegation = document.querySelectorAll('.product-grid, .recommendation .col-wrap, .basket_wrap');

        function counter(e) {

            if (e.target.matches('[class*="btn_"]')) {
                e.preventDefault();

                var valCount = parseInt(e.target.parentNode.querySelector('input').value);

                var ct = (function() {
                    var val = valCount;

                    return {
                        add: function() {
                            return valCount + 1;
                        },

                        remove: function() {
                            if (valCount > 1) {
                                return valCount - 1;
                            } else {
                                return valCount = 1;
                            }
                        }
                    }
                })();


                if (e.target.classList.contains('btn_plus')) {
                    e.target.parentNode.querySelector('input').value = ct.add();
                } else {
                    e.target.parentNode.querySelector('input').value = ct.remove();
                }
            }
        }

        function isNumberKey(e) {
            var charCode = (e.which) ? e.which : e.keyCode;

            if (e.target.matches('.counter-wrap input')) {

                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                    return false;
                }
                if (e.target.value === '0' || e.target.value === '') {
                    e.target.value = 1;
                }
            }
        }

        if (input) {
            while (j--) {
                input[j].addEventListener('keypress', isNumberKey, false);
            }
        }

        for (var i = 0; i <= delegation.length; i++) {
            if (delegation[i]) {
                delegation[i].addEventListener('click', counter, false);
            }
        }
    }

    function initStickyHeader() {
        var header = document.querySelector('.header');

        function checkPadding() {
            var heightH = header.offsetHeight;
            var bodyPadTop = parseInt(getComputedStyle(document.body).paddingTop.slice(0, -2));

            if (document.querySelector('.header + .intro')) {
                //don't change the body top padding
            } else {
                if (bodyPadTop < heightH || bodyPadTop > heightH) {
                    document.body.style.paddingTop = heightH + 'px';
                }
            }
        }

        function stickyHeader(e) {
            var top = window.scrollY || window.pageYOffset;

            if (top > 0 || top < 0) {
                header.classList.add('fixed');
            } else {
                header.classList.remove('fixed');
            }
        }

        checkPadding();
        stickyHeader();

        document.addEventListener('scroll', stickyHeader, false);
        window.addEventListener('resize', checkPadding, false);
        window.addEventListener('orientationchange', checkPadding, false);
    }

    function initFilter() {
        var form = jQuery('form#form-product-sort');

        jQuery(form).on('change', '.choose-sort-product', function () {
            form.submit();
        });
    }

    function initLoadMore() {
        ;(function ($, window, document) {
            "use strict";
            $.fn.loadmore_scroll = function (options) {
                // reset
                $(window).unbind('loadmore_start');

                var load_more_btn=$('.view-more');

                var options = $.extend({
                        nextSelector: false,
                        navSelector: false,
                        itemSelector: false,
                        contentSelector: false,
                        maxPage: false,
                        loader: false
                    }, options),
                    loading = false,
                    finished = false,
                    nextpage_url = $(options.nextSelector).attr('href'); // init next url

                // validate options and hide navigation
                if ($(options.nextSelector).length && $(options.navSelector).length && $(options.itemSelector).length && $(options.contentSelector).length) {
                    //$(options.navSelector).hide();
                } else {
                    // set finished true
                    finished = true;
                }

                var load_posts = function () {
                    var last_elem = $(options.contentSelector).find(options.itemSelector).last();
                    var dur = 150;
                    // set loader and loading
                    if (options.loader)
                        $(options.loader).show();
                    loading = true;

                    $.ajax({
                        url: nextpage_url,
                        dataType: 'html',
                        beforeSend: function() {
                            jQuery('.btn-more').addClass('active');
                            //load_more_btn.addClass('action');
                        },

                        success: function (data) {
                            jQuery('.btn-more').removeClass('active');
                            var obj = $(data),
                                elem = obj.find(options.itemSelector),
                                next = obj.find(options.nextSelector);

                            if (next.length) {
                                nextpage_url = next.attr('href');
                            } else {
                                finished = true;
                            }

                            last_elem.after(elem);

                            if (options.loader)  $(options.loader).hide();

                            $(document).trigger('loadmore_adding_elem');

                            elem.addClass('loadmore-animated');
                            setTimeout(function () {
                                loading = false;
                                $(last_elem).closest(".filter-listing-grid").removeClass("loading-more");
                                elem.removeClass('loadmore-animated');
                                $(document).trigger('loadmore_added_elem');
                                //load_more_btn.removeClass('action');
                                if(finished) load_more_btn.remove();
                            }, 0);
                        }

                    });

                };

                $('.view-more').on('click', '.btn', function(){
                    $(this).closest(".filter-listing-grid").addClass("loading-more");
                    load_posts();
                });

            }

        })(jQuery, window, document);

        var loadmore_scroll = {
            'nextSelector': 'a.nextpostslink',
            'navSelector': '.wp-pagenavi',
            'itemSelector': '.product-item',
            'contentSelector': '.product-grid'
        };

        $('.btn').loadmore_scroll(loadmore_scroll);
    }

    function initSelect() {
        var form = document.getElementById('form-product-sort');
        var btnS = document.querySelectorAll('.select-wrap .choose-sort-product li');
        var selectT = document.querySelector('.select');
        var i = 0;

        function select(e) {
            var txt = e.target.innerText;
            var data = e.target.getAttribute('data-name');

            e.target.parentElement.querySelectorAll('li')[0].innerText = txt;
            selectT.children[0].setAttribute('value', data);

            if (!e.target.classList.contains('first')) {
                form.submit();
            }

            if (e.target.parentElement.classList.contains('active')) {
                e.target.parentElement.classList.remove('active');
            } else {
                e.target.parentElement.classList.add('active');
            }
        }

        if (btnS) {
            for (; i < btnS.length; i++) {
                btnS[i].addEventListener('click', select, false);
            }
        }
    }

    function initSumBasket() {
        var btn = jQuery(document);

        function basket(e) {
            var arr = [];
            var basket = jQuery('.basket');

            jQuery('.bg-items td.product-quantity .quantity input').each(function() {
                var val = parseInt(jQuery(this).val());

                if (jQuery(this).val() === '') {
                    val = 0;
                }

                arr.push(val);
            });


            function getSum(a, b) {
                return a + b;
            }

            var sum = arr.reduce(getSum, 0);

            basket.attr('data-count', sum);
        }

        btn.on('click', '.getSum', function(e) {
            basket();
        });

        btn.on('keyup', '.bg-items td.product-quantity .quantity input', function(e) {
            var charCode = (e.which) ? e.which : e.keyCode;

            if (charCode === 13) {
                basket();
            }
        });

        btn.on('click', '.remove', function () {
            var product_id = $(this).data('product_id');
            var basket = jQuery('.basket');
            jQuery.ajax({
                type: 'post',
                url: ajax_url.ajaxurl,
                data: {
                    action: "bg_delete_cart",
                    id: product_id,
                },
                success: function (data) {
                    basket.attr('data-count', data.cart_quantity);
                }
            });

        });
    }

    function initSimulateClick() {
        var sP = document.querySelector('.recommendation  .col-first, .recommendation .item');
        var delegation = document.querySelector('.recommendation .col-wrap, .archive .product-grid');

        function simulateClick(e) {
            e.preventDefault();
            var _ = e.target;
            var src = jQuery(_).parents('.item, .col-first').find('.inner-box').attr('data-src');
            /*console.log(this);
            console.log(src);*/

            if (e.target.matches('[class*="btn_"]') || e.target.matches('.btn') || e.target.matches('.inner-holder, input, .row')) {
                return false;
            }

            if (e.target.matches('.item, .col-first, .col-wrap')) {
                var box = jQuery(_).find('.inner-box');

                src = box.attr('data-src');
            }

            // console.log(_, src);
            window.location.href = src;

        }

        if (delegation) {
            /*console.log(sP)
            if (!sP) {*/
                delegation.addEventListener('click', simulateClick, false);
            /*}*/
        }
    }
    function initBasket() {

        var basket = jQuery('header .basket'),
            close = jQuery('.basket_wrap .close');

        basket.on('click', function(e) {
            e.preventDefault();
            jQuery('.basket_wrap').addClass('open');
            jQuery('body').addClass('openBasket');
        });
        close.on('click', function(e) {
            e.preventDefault();
            jQuery('.basket_wrap').removeClass('open');
            jQuery('body').removeClass('openBasket');
        });

// $(document).on('click', function (event) {
//     if (!$(event.target).closest('.basket').length) {
//         jQuery('.basket_wrap').removeClass('open');
//         jQuery('body').removeClass('openBasket');
//     }
// });

        $(document).on('click.update', '.update-cart', function (e) {
            e.preventDefault();
            var quantity = $(this).parent().find('input').val();
            var key = $(this).parents('li').find('a.del').data('delete');
             var id = $(this).parents('li').find('a.del').data('id');
             var _ = this;

             $('.spin-wrap').addClass('active');

            jQuery.ajax({
                type: 'post',
                url: ajax_url.ajaxurl,
                data: {
                    action: "bg_change_from_cart",
                    quantity: quantity,
                    cart_item_key: key,
                    id: id
                },

                success: function success(data) {
                    //total
                    $('.price-cart').text(data.total);

                    //count
                    $('.basket').attr('data-count',data.cart_quantity);
                    $(_).parents('.text-content').siblings('.total_price').empty().append('<b>' + data.subtotal +  '</b>');
                    $('.spin-wrap').removeClass('active');
                }
            });

        }).on('click', '.del', function (e) {
            e.preventDefault();
            var cart_item_key = $(this).attr('data-delete');
            var id = $(this).attr('data-id');
            jQuery.ajax({
                type: 'post',
                url: ajax_url.ajaxurl,
                data: {
                    action: "bg_delete_from_cart",
                    cart_item_key: cart_item_key,
                    id: id
                },

                success: function success(data) {
                    console.log(data.id);
                    //remove product-item
                    var remuveBlock = 'li.woocommerce-cart-item-' + data.id;

                    $(remuveBlock).remove();
                    //total
                    $('.price-cart').text(data.total);
                    //count
                    $('.basket').attr('data-count',data.cart_quantity);

                }
            });
        });

    }

    jQuery(function() {
        initStickyHeader();
        initBurgerMenu();
        activateResizeHandler();
        initBasket();
        initCounter();
        getAjax();
        initFilter();
        initLoadMore();
        initSelect();
        initSumBasket();
        initSimulateClick();
    });
}
