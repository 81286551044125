'use strict';

/**
 *  Load modules
 */
import $ from 'jquery';
import echo from './modules/echo.js';

document.addEventListener('DOMContentLoaded', function() {
    echo();
}, false);
